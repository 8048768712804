import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  Table,
  Paper,
  MenuItem,
  TableRow,
  TextField,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  FormControl,
  TableContainer,
  CircularProgress,
} from '@material-ui/core';
import {Pagination} from '@material-ui/lab';
import GetAppIcon from '@material-ui/icons/GetApp';

import {useNavigate} from 'react-router-dom';

import {
  TblRow,
  DrawerFilter,
  ModalNewLead,
  ModalSelectCampaign,
} from './components';

import {useStyles} from './styles';
import {
  ILeadCalls,
  LeadService,
  ILeadCallsFilters,
} from 'shared/services/api/LeadService';
import {
  IAdmUser,
  InterviewerService,
} from 'shared/services/api/InterviewerService';
import {
  IUnityEstablishment,
  EstablishmentService,
} from 'shared/services/api/EstablishmentService';
import {
  ILead,
  LeadSituationService,
} from 'shared/services/api/LeadSituationService';
import {feedback} from 'shared/services/alertService';
import {useTitle, useTerm, useUser, useDebounce} from 'shared/hooks';
import {errorResponse} from 'shared/utils/errorResponse';
import {UserService} from 'shared/services/api/UserService';
import {CourseService, ICourse} from 'shared/services/api/CourseService';
import {
  Header,
  Button,
  NoRegister,
  ModalConfirm,
  TableSkeleton,
} from 'shared/components';
import {CampaignService, ICampaign} from 'shared/services/api/CampaignService';
import {useLeadFilter} from './hooks';
import {downloadPDF} from 'shared/utils/downloadPdfFromBase64';
import {
  IUserProfile,
  IUserProfilePagination,
  UserProfileService,
} from 'shared/services/api/UserProfileService';

export const Lead: React.FC = () => {
  const {setAppliedFilters, appliedFilters} = useLeadFilter();
  const [idLead, setIdLead] = useState('');
  const [filter, setFilter] = useState('');
  const [tableFiltered, setTableFiltered] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  // const [leads, setLeads] = useState<ILead[]>([]);
  const [idCampaign, setIdCampaign] = useState('');
  const [countFilter, setCountFilter] = useState(0);
  const [course, setCourse] = useState<ICourse[]>([]);
  const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
  const [attendants, setAttendants] = useState<IUserProfile[]>([]);
  const [leadCalls, setLeadCalls] = useState<ILeadCalls[]>([]);
  const [leadCallsCount, setLeadCallsCount] = useState(0);
  const [openDrawerFilter, setOpenDrawerFilter] = useState(false);
  const [openModalNewLead, setOpenModalNewLead] = useState(false);
  const [interviewers, setInterviewers] = useState<IAdmUser[]>([]);
  const [openModalConfirmExclude, setOpenModalConfirmExclude] = useState(false);
  const [openModalSelectCampaign, setOpenModalSelectCampaign] = useState(false);
  const [establishments, setEstablishments] = useState<
    Omit<IUnityEstablishment[], 'unidades'>
  >([]);
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(1);
  const [defaultFilter, setDefaultFilter] = useState('');

  const navigate = useNavigate();
  const {terms} = useTerm();
  const {setTitle} = useTitle();
  const {debounce} = useDebounce();
  const {data: user} = useUser();
  const {noWrap, headAct, headLead, select, baseboard, buttonProgress} =
    useStyles();

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Lead')),
    [user?.permissoes],
  );

  const permissionsMeeting = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Atendimento')),
    [user?.permissoes],
  );

  const canDelete = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Excluir')),
    [permissions],
  );

  const canIntegrate = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Integrar')),
    [permissions],
  );

  const canRegister = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Incluir')),
    [permissions],
  );

  // const canEdit = useMemo(
  //   () => !!permissions?.find((i) => i.nome.includes('Incluir')),
  //   [permissions],
  // );

  const canList = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Listar')),
    [permissions],
  );

  const canMeet = useMemo(
    () => !!permissionsMeeting?.find((i) => i.nome.includes('Listar')),
    [permissionsMeeting],
  );

  const permissionList = useMemo(
    () => permissions?.find((i) => i.nome.includes('Listar')),
    [permissions],
  );

  const userTimezone = useMemo(
    () => (user?.timezone ? user.timezone : -3),
    [user?.timezone],
  );

  const getLeadCalls = useCallback(() => {
    setLoading(true);
    debounce(async () => {
      try {
        const response = await LeadService.getLeadCalls(
          take,
          skip,
          {
            ...appliedFilters,
            campoDePesquisa: defaultFilter,
          },
          userTimezone,
        );

        if (response) {
          setLeadCalls(response.leads);
          setLeadCallsCount(response.qtdTotalDeRegistros);
          setOpenDrawerFilter(false);
        } else {
          feedback(`Sua pesquisa não retornou resultados!`, 'warning');
        }
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        setCountFilter(
          Object.values(appliedFilters)
            .map((item) => !!item)
            .filter(Boolean).length,
        );

        setLoading(false);
      }
    });
  }, [debounce, defaultFilter, appliedFilters, skip, take, userTimezone]);

  const exportLeadCalls = useCallback(async () => {
    setLoadingExport(true);
    try {
      const response = await LeadService.getExportLeadCalls(
        leadCallsCount,
        skip,
        {
          ...appliedFilters,
          campoDePesquisa: defaultFilter,
        },
      );
      if (!response) feedback('Ocorreu um erro...', 'error');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoadingExport(false);
    }
  }, [defaultFilter, appliedFilters, leadCallsCount, skip]);

  const downloadLeadReport = useCallback(async () => {
    setLoadingReport(true);
    try {
      const response = await LeadService.postAccompanimentReport();
      downloadPDF(response, 'Relatorio Lead.pdf');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoadingReport(false);
    }
  }, []);

  const getEstablishments = useCallback(async () => {
    try {
      const response = await EstablishmentService.getEstablishments(
        permissionList?.id,
      );

      if (response) {
        setEstablishments(response.estabelecimentos);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [permissionList?.id]);

  const getCampaigns = useCallback(async () => {
    try {
      const response = await CampaignService.getCampaigns({
        idPermissao: permissionList?.id,
        idEstabelecimentoDaOferta:
          appliedFilters.idEstabelecimento !== 0
            ? appliedFilters.idEstabelecimento
            : '',
      });

      if (response) {
        setCampaigns(
          response.filter((item) => item.idSituacaoDeCampanha === 2),
        );
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [appliedFilters.idEstabelecimento, permissionList?.id]);

  // const getLeads = useCallback(async () => {
  //   try {
  //     const response = await LeadSituationService.getLeads();
  //     if (response) {
  //       setLeads(response);
  //     }
  //   } catch (error) {

  //     feedback(errorResponse(error), 'error');
  //   }
  // }, []);

  const getAttendants = useCallback(async () => {
    try {
      const response = await UserProfileService.getUserPermissions(
        filter,
        tableFiltered,
        take,
        skip,
      );

      if (response) {
        setAttendants(response.data);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [filter, skip, tableFiltered, take]);

  const getInterviewers = useCallback(async () => {
    try {
      const response = await InterviewerService.getAdmUserInterviewer();

      if (response) {
        setInterviewers(response);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const getCourse = useCallback(async () => {
    try {
      const response = await CourseService.getCourses();

      if (response) setCourse(response);
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, []);

  const setCleanFilter = useCallback(() => {
    const cleanFilters: ILeadCallsFilters = {
      idEstabelecimento: 0,
      idCampanha: 0,
      idSituacaoDoLead: 0,
      idCurso: 0,
      idCiclo: 0,
      idUsuarioAtendente: 0,
      idUsuarioEntrevistador: 0,
      inscricaoIniciaEm: null,
      inscricaoTerminaEm: null,
      agendamentoIniciaEm: null,
      agendamentoTerminaEm: null,
      campoDePesquisa: null,
      isVendaPerdida: false,
      isControleTipoVaga: false,
      isControleTipoListaEspera: false,
    };
    setAppliedFilters(cleanFilters);
  }, [setAppliedFilters]);

  const handleCloseModalSelectCampaign = useCallback(() => {
    setOpenModalSelectCampaign(false);
  }, []);

  const handleCloseModalLead = useCallback(() => {
    getLeadCalls();
    setOpenModalNewLead(false);
    setIdLead('');
    setIdCampaign('');
  }, [getLeadCalls]);

  const handleOpenExternalModalNewLead = useCallback(() => {
    setOpenModalNewLead(true);

    handleCloseModalSelectCampaign();
  }, [handleCloseModalSelectCampaign]);

  const handleEditeLead = useCallback((idLead: string, idCampaign: string) => {
    setIdLead(idLead);
    setIdCampaign(idCampaign);
  }, []);

  const handleDeleteLead = useCallback((idLead: string) => {
    setOpenModalConfirmExclude(true);

    setIdLead(idLead);
  }, []);

  const handleConfirmLeadDeleted = useCallback(async () => {
    try {
      setLoading(true);
      await LeadService.deleteCall(idLead);

      getLeadCalls();

      feedback('Lead excluído com sucesso', 'success');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setIdLead('');
      setOpenModalConfirmExclude(false);
      setLoading(false);
    }
  }, [getLeadCalls, idLead]);

  const handleChangePage = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setSkip(value);
    },
    [],
  );

  const handleFilter = useCallback((value: string) => {
    setDefaultFilter(value);
    setSkip(1);
  }, []);

  useEffect(() => setTitle(terms.termoLead), [setTitle, terms.termoLead]);

  useEffect(() => {
    if (!canList) navigate('/');
  }, [canList, navigate]);

  useEffect(() => {
    getLeadCalls();
  }, [getLeadCalls]);

  useEffect(() => {
    // getLeads();
    getCourse();
    getCampaigns();
    getAttendants();
    getInterviewers();
    getEstablishments();
  }, [
    // getLeads,
    getCourse,
    getCampaigns,
    getAttendants,
    getInterviewers,
    getEstablishments,
  ]);

  return (
    <>
      <Header
        filter={defaultFilter}
        setFilter={handleFilter}
        filterLabel="Pesquisar por leads, email e telefone"
        disabledButton={!canRegister || loading}
        onClick={() => setOpenModalSelectCampaign(true)}>
        <div>
          <DrawerFilter
            // leads={leads}
            course={course}
            loading={loading}
            toFilters={appliedFilters}
            campaigns={campaigns}
            attendants={attendants}
            canRegister={canRegister}
            countFilter={countFilter}
            setToFilters={(value) => {
              setAppliedFilters(value);
              setSkip(1);
            }}
            interviewers={interviewers}
            establishments={establishments}
            setCleanFilter={setCleanFilter}
            openDrawerFilter={openDrawerFilter}
            setOpenDrawerFilter={setOpenDrawerFilter}
          />
        </div>
        <Box minWidth={150}>
          <Button
            fullWidth
            label="Exportar"
            variant="contained"
            disabled={!canList || loading || loadingExport}
            onClick={exportLeadCalls}
            startIcon={<GetAppIcon />}>
            {loadingExport && (
              <CircularProgress size={24} className={buttonProgress} />
            )}
          </Button>
        </Box>
        <Box minWidth={150}>
          <Button
            
            fullWidth
            label="Relatórios"
            variant="contained"
            // disabled={loading || loadingReport}
            disabled
            onClick={downloadLeadReport}
            startIcon={<GetAppIcon />}>
            {loadingReport && (
              <CircularProgress size={24} className={buttonProgress} />
            )}
          </Button>
        </Box>
      </Header>
      <Box marginTop={5} />
      <Box marginBottom={2} display="flex" alignItems="center">
        <FormControl component="fieldset">
          <TextField
            inputProps={{className: select}}
            select
            variant="outlined"
            value={take}
            onChange={({target}) => {
              setTake(Number(target.value));
              setSkip(1);
            }}>
            {[10, 15, 20, 50].map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <Box marginLeft={2}>Linhas por página</Box>
      </Box>
      {loading ? (
        <TableSkeleton btnAct={3} columns={5} rowsBody={10} rectWidth="40%" />
      ) : (
        <TableContainer
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onWheel={(event: any) =>
            event.deltaY > 0
              ? event.target.scrollBy(100, 0)
              : event.target.scrollBy(-100, 0)
          }
          id="tableContainer"
          component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={headAct}>Ações</TableCell>
                <TableCell className={headLead}>{terms.termoLead}</TableCell>
                <TableCell>Telefone</TableCell>
                <TableCell>Oferta</TableCell>
                <TableCell>Estabelecimento</TableCell>
                <TableCell>Agenda</TableCell>
                <TableCell>Situação</TableCell>
                <TableCell className={noWrap}>Data de inscrição</TableCell>
                <TableCell>Campanha</TableCell>
                <TableCell>Origem</TableCell>
                <TableCell>Candidato</TableCell>
                <TableCell className={noWrap} align="center">Controle de vagas</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {leadCalls.map((item) => (
                <TblRow
                  item={item}
                  key={item.id}
                  idFunnel={item.idFunil}
                  idLead={idLead}
                  canMeet={canMeet}
                  loading={loading}
                  canDelete={canDelete}
                  editLead={handleEditeLead}
                  canIntegrate={canIntegrate}
                  deleteLead={handleDeleteLead}
                />
              ))}
            </TableBody>
          </Table>

          <NoRegister data={leadCalls.length} />
        </TableContainer>
      )}
      <Box className={baseboard}>
        <Pagination
          page={skip}
          count={Math.ceil(leadCallsCount / take)}
          onChange={handleChangePage}
        />
        <Typography variant="caption" style={{color: '#9E9E9E'}}>
          {`${leadCalls.length} do total de ${leadCallsCount} linhas`}
        </Typography>
      </Box>
      <ModalConfirm
        opened={openModalConfirmExclude}
        onClick={handleConfirmLeadDeleted}
        onClose={() => {
          setOpenModalConfirmExclude(false), setIdLead('');
        }}
        loading={loading}
      />
      <ModalSelectCampaign
        openModal={openModalSelectCampaign}
        onClick={handleOpenExternalModalNewLead}
        onClose={handleCloseModalSelectCampaign}
        campaigns={campaigns}
        setIdCampaign={setIdCampaign}
        idCampaign={idCampaign}
        loading={loading}
      />
      <ModalNewLead
        openModal={openModalNewLead}
        idCampaign={idCampaign}
        onClose={handleCloseModalLead}
      />
    </>
  );
};
