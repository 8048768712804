import React from 'react';

import FilterListIcon from '@material-ui/icons/FilterList';
import EditIcon from '@material-ui/icons/Edit';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/Check';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PersonAddIcon from '@material-ui/icons/PersonAdd';


interface IProps {
  type: string;
}

const TimelineDescriptionIcon: React.FC<IProps> = ({type}) => {
  const renderIcon = (type: string) => {
    switch (type.toLowerCase()) {
      case 'avançar etapa':
        return <FilterListIcon fontSize="small" style={{marginRight: 4}} />;
      case 'formulário':
        return <EditIcon fontSize="small" style={{marginRight: 4}} />;
      case 'atendimentos':
        return <AccessTimeIcon fontSize="small" style={{marginRight: 4}} />;
      case 'tarefas':
        return <CheckIcon fontSize="small" style={{marginRight: 4}} />;
      case 'pagamentos':
        return <AttachMoneyIcon fontSize="small" style={{marginRight: 4}} />;
      case 'funil':
        return <FilterListIcon fontSize="small" style={{marginRight: 4}} />;
      case 'inscrito':
        return (
          <PersonAddIcon fontSize="small" style={{marginRight: 4}} />
        );
      default:
        break;
    }
  };

  return <>{renderIcon(type)}</>;
};

export default TimelineDescriptionIcon;
