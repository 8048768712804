import {Api} from 'shared/services/axiosConfig';
import {IRequestResult} from '../../../interfaces/IRequestResult';

export interface IFeeOfers {
  data: IFeeOfersData[];
  qtdTotalDeRegistros: number;
}
export interface IFeeOfersData {
  id: string;
  idCampanha: string;
  idCurso: string;
  nomeCurso: string;
  idCiclo: string;
  nomeCiclo: string;
  idTurno: string;
  nomeTurno: string;
  idEstabelecimento: string;
  nomeEstabelecimento: string;
  estabelecimentoCnpj: any;
  ativo: boolean;
  idSituacaoValorOferta: number;
  valorOferta: number;
  possuiPix: boolean;
  possuiCartaoCredito: boolean;
  complementos1DaOferta: IComplementary[];
  complementos2DaOferta: IComplementary[];
}

export interface IComplementary {
  id: string;
  nome: string;
  ativo: boolean;
}

export interface IConfigTaxa {
  id: string;
  nome: string;
  dataVigenciaInicial: string;
  dataVigenciaFinal: string;
  valorCampanha: number;
  vencimento: Vencimento[];
  dataVencimento: any;
  numeroDiasVencimento: number;
  competencia: Competencum[];
}

export interface Vencimento {
  nomeTipoVencimento: string;
  idTipoVencimento: number;
  selecionado: boolean;
}

export interface IBankAccount {
  codigoBancoConta: string;
  nomeCedente: string;
  selecionado: boolean;
}

export interface ITeste {
  idOferta: string;
  idSituacaoValorOferta: number;
  valorOferta: number;
  possuiPix: boolean;
  codigoBancoPix: string | null;
  codigoBancoContaPix: string | null;
  possuiCartaoCredito: boolean;
  codigoBancoCartaoCredito: string | null;
  codigoBancoContaCartaoCredito: string | null;
  codigoEvento: string | null;
}

export interface Competencum {
  nomeCompetencia: string;
  idTipoCompetencia: number;
  selecionado: boolean;
}

export interface IFinancialEvent {
  codigoEvento: string;
  descricaoEvento: string;
  selecionado: boolean;
}

export interface IPut {
  idConfiguracaoTaxa: string;
  valorCampanha: string | number;
  codigoTipoParcela: string;
  codigoSituacaoParcela: string;
  idTipoVencimento: number;
  dataVencimento: string;
  numeroDiasVencimento: number | undefined;
  idTipoCompetencia: number;
}

export interface IPaymentInfo {
  codigoBanco: string;
  nomeBanco: string;
  selecionado: boolean;
}

const getConfiguracaoTaxa = async (
  idCampamnha: string,
): Promise<IConfigTaxa> => {
  try {
    const {data} = await Api.get(`/configuracaotaxa/${idCampamnha}`);

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.data.data}`);
  }
};

const getOfertas = async (
  idCampamnha: string,
  skip: number,
  take: number,
): Promise<IFeeOfers> => {
  try {
    const {data} = await Api.get(
      `campanha/ofertasporcampanha/${idCampamnha}/paginado?take=${take}&skip=${skip}`,
    );

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.data.data}`);
  }
};

const getOfertasTaxa = async (
  idCampamnha: string,
  skip: number,
  take: number,
): Promise<IFeeOfers> => {
  try {
    const {data} = await Api.get(
      `campanha/ofertasporcampanha/${idCampamnha}/paginado?take=${take}&skip=${skip}&paraConfiguracaoTaxa=true`,
    );

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.data.data}`);
  }
};

const getLocaisPagamento = async (
  idOferta: string,
  idTipoTaxa: number,
): Promise<IPaymentInfo[]> => {
  try {
    const {data} = await Api.get(
      `/configuracaotaxa/locaispagamento/${idOferta}?idtipotaxa=${idTipoTaxa}`,
    );
    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.data.data}`);
  }
};

const getContasBancarias = async (
  idOferta: string,
  codigoBanco: string,
  idTipoTaxa: number,
): Promise<IBankAccount[]> => {
  try {
    const {data} = await Api.get(
      `/configuracaotaxa/contasbancarias/${idOferta}/${codigoBanco}?idtipotaxa=${idTipoTaxa}`,
    );
    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.data.data}`);
  }
};

const patchOferta = async (teste: ITeste): Promise<any> => {
  try {
    const {data} = await Api.patch(
      `/configuracaotaxa/oferta/${teste.idOferta}`,
      teste,
    );
    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.data.data}`);
  }
};

const getEventosFincanceiros = async (
  idOferta: string,
): Promise<IFinancialEvent[]> => {
  try {
    const {data} = await Api.get(
      `/configuracaotaxa/eventosfinanceiros/${idOferta}`,
    );
    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.data.data}`);
  }
};

const putConfigTaxa = async (
  payload: IPut,
  idConfigTaxa: string,
): Promise<IRequestResult> => {
  try {
    await Api.put(`/configuracaotaxa/${idConfigTaxa}`, payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(`Erro ao salvar. ${error.data.data}`);
  }
};

export const FeeService = {
  getOfertas,
  getOfertasTaxa,
  patchOferta,
  putConfigTaxa,
  getLocaisPagamento,
  getContasBancarias,
  getEventosFincanceiros,
  getConfiguracaoTaxa,
};
