import {IRequestResult} from 'shared/interfaces/IRequestResult';
import {Api} from 'shared/services/axiosConfig';

export interface IUrlComposition {
  idCampanha: string;
  idComposicaoUri: number;
  urlBase: string;
}

export interface IUrlResponse {
  idEstabelecimento: string;
  estabelecimento: string;
  idTipoServicoPublicidade: string;
  idCurso: string;
  curso: string;
  idComposicaoUri: number;
  parametrosLinkCustomizado: IParametroLinkCustomizado[];
  uri: string;
}

export type TParametro =
  | 'utm_source'
  | 'utm_medium'
  | 'utm_campaign'
  | 'utm_term'
  | 'utm_content'
  | 'utm_id';

export interface IParametroLinkCustomizado {
  parametro: TParametro;
  valor: string;
  obrigatorio: boolean;
}

export interface IPutUrlResponse {
  idCampanha: string;
  idEstabelecimento: string;
  idCurso: string;
  idComposicaoUri: string;
  idTipoServicoPublicidade: string;
  parametrosLinkCustomizado: IParametroLinkCustomizado[];
}

const getExternalUrl = async (
  dataReq: IUrlComposition,
): Promise<IUrlResponse[]> => {
  try {
    const {data} = await Api.get(
      `ComposicaoUriExterna?idTipoServicoPublicidade=1&idCampanha=${dataReq.idCampanha}&idComposicaoUri=${dataReq.idComposicaoUri}&urlBase=${dataReq.urlBase}`,

      //URL PARA DEV EM LOCAL
      // `ComposicaoUriExterna?idTipoServicoPublicidade=1&idCampanha=0a5d50f7-7028-4a10-bd94-08dd07bddf75&idComposicaoUri=1&urlBase=https://dgt01-dev.crmform.gvdasa.com.br/formulario`,
    );

    return data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const putLinkCustomizado = async (
  payload: IPutUrlResponse,
): Promise<IRequestResult> => {
  try {
    await Api.put(`/ComposicaoUriExterna`, payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(`Erro ao salvar. ${error.data.data}`);
  }
};

export const ExternalUrlService = {
  getExternalUrl,
  putLinkCustomizado,
};
