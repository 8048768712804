import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  MenuItem,
  Paper,
  Select,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';

import FileCopy from '@material-ui/icons/FileCopy';
import MailIcon from '@material-ui/icons/Mail';
import EventIcon from '@material-ui/icons/Event';
import PhoneIcon from '@material-ui/icons/Phone';
import SchoolIcon from '@material-ui/icons/School';
import PersonIcon from '@material-ui/icons/Person';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import {useNavigate, useParams} from 'react-router-dom';

import {
  Tarefas,
  Payment,
  Atendimentos,
  ModalNovaTarefa,
  ModalAgendamento,
  ModalEditAgendamento,
} from './components';
import {useUser} from 'shared/hooks';
import {feedback} from 'shared/services/alertService';
import {OrigemLead} from './components/OrigemLead';
import {Environment} from 'shared/env';
import {errorResponse} from 'shared/utils/errorResponse';
import {Button, Footer} from 'shared/components';
import {getCardsDateTime} from 'shared/utils/getCardsDateTime';
import {ModalSeeMoreCards} from 'pages/Lead/components/ModalSeeMoreCards';
import TimelineDescriptionIcon from './components/TimelineIcons';

import {
  EditLeadService,
  IAtendimentosLead,
  IEditResponse,
  ILead,
} from 'shared/services/api/EditLeadService';
import {
  ILossReasons,
  LossReasonsService,
} from 'shared/services/api/LossReasonsService';

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      backgroundColor: '#eaeaea',
      border: '1px solid',
      borderColor: theme.palette.grey[300],
    },
    card: {
      backgroundColor: '#7EE2CB',
    },
    cardPerda: {
      backgroundColor: '#f7b8b8',
    },
    customCard: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardBorder: {
      border: '1px solid',
      borderColor: theme.palette.grey[300],
    },
  }),
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{p: 3}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Grid>
  );
}

function TabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const EditLead: React.FC = () => {
  const LOSS_REASON_ID_DEFAULT = null;
  const {
    idLead = '0',
    idCampanha = '0',
    idOferta = '0',
  } = useParams<'idLead' | 'idCampanha' | 'idOferta'>();
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const handleChange = useCallback(
    // eslint-disable-next-line
    (_: React.ChangeEvent<{}>, newValue: number) => {
      setValue(newValue);
    },
    [],
  );

  const classes = useStyles();
  const {data: user} = useUser();
  const [lead, setLead] = useState<ILead>();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingEtapa, setIsLoadingEtapa] = useState(false);
  const [isLoadingLead, setIsLoadingLead] = useState(true);

  const [lossReasons, setLossReasons] = useState<ILossReasons[]>([]);
  const [lossReasonLead, setLossReasonLead] = useState<ILossReasons>();
  const [lossReasonSelected, setLossReasonSelected] = useState({
    idMotivoPerda: '0',
    idLead: idLead,
  });
  const [hideSaveButton, setHideSaveButton] = useState(true);
  const [isLeadLoss, setIsLeadLoss] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [loadPerda, setLoadPerda] = useState(false);

  const [atendimento, setAtendimentos] = useState<IAtendimentosLead>();

  const makeAvatar = useMemo(() => {
    {
      if (user) {
        const first = user.nome.slice()[0].toUpperCase();
        const last = user.nome.split(' ').slice(-1)[0][0].toUpperCase();
        return first + last;
      }
    }
  }, [user]);

  const [openModal, setOpenModal] = useState(false);
  const [openModalCards, setOpenModalCards] = useState(false);
  const [cardsToShow, setCardsToShow] = useState(5);

  const [internalEditForm, setInternalEditForm] = useState<
    IEditResponse | undefined
  >();

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleCloseModalCards = () => setOpenModalCards(false);

  const copyToClipboard = (uri: string) => {
    navigator.clipboard.writeText(uri);
    feedback('URL copiada para sua área de transferência!', 'success');
  };

  const getLeadsLossReason = async () => {
    setIsLoading(true);
    try {
      const response = await LossReasonsService.getLossReasons();
      const responseFiltered = response.filter(
        (loss) => loss.id === lead?.idMotivoPerda,
      );
      setLossReasonLead(responseFiltered[0]);
    } catch (error: any) {
      feedback(error.Mensagem, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (lead?.idMotivoPerda !== LOSS_REASON_ID_DEFAULT) {
      getLeadsLossReason();

      if (lead !== undefined) {
        setIsLeadLoss(true);
      }
    }

    if (lead) {
      setCardsToShow(
        lead?.linhaDoTempo.length >= 5 ? 5 : lead?.linhaDoTempo.length,
      );

      const shouldHideButton = lead?.funil.find(
        (item) =>
          item.situacao.toLowerCase() === 'integrado' &&
          item.atual === true &&
          item.finalizado === true,
      );

      setHideSaveButton(shouldHideButton ? true : false);
    }
  }, [lead]);

  useEffect(() => {
    async function getLead() {
      setIsLoadingLead(true);
      try {
        const response = await EditLeadService.getLead(idLead);
        setLead(response);
      } catch (error: any) {
        feedback(error.Mensagem, 'error');
      } finally {
        setIsLoadingLead(false);
      }
    }
    getLead();
  }, [idLead, refresh]);

  useEffect(() => {
    async function handleGetLossReasons() {
      try {
        const response = await LossReasonsService.getLossReasons();
        setLossReasons(response);
        setIsLoading(false);
      } catch (error: any) {
        feedback(error.Mensagem, 'error');
      }
    }
    handleGetLossReasons();
  }, []);

  useEffect(() => {
    async function getAtendimentos() {
      setIsLoading(true);
      try {
        const response = await EditLeadService.getAtendimentos(idLead);
        setAtendimentos(response);
      } catch (error: any) {
        feedback(error.Mensagem, 'error');
      } finally {
        setIsLoading(false);
      }
    }
    getAtendimentos();
  }, [idLead, refresh]);

  const nextStep = useCallback(async () => {
    setIsLoadingEtapa(true);
    try {
      await EditLeadService.postNextStep({idLead: idLead});

      feedback('Etapa avançada com sucesso', 'success');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setIsLoadingEtapa(false);
      setTimeout(() => {
        setRefresh(!refresh);
      }, 100);
    }
    //eslint-disable-next-line
  }, [refresh]);

  const usaAtendimento = atendimento?.atendimentos.find(
    (objeto) => objeto.usaAgendamento,
  );

  const handlePostPerda = useCallback(async () => {
    try {
      await EditLeadService.postLeadLossReason(lossReasonSelected);

      setIsLeadLoss(true);
      feedback('Atendimento alterado com sucesso', 'success');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      handleCloseModal();
      setTimeout(() => {
        setRefresh(!refresh);
      }, 100);
    }
  }, [lossReasonSelected, refresh]);

  const handleDesfazerPerda = useCallback(async () => {
    setLoadPerda(true);
    try {
      await EditLeadService.postLeadLossReason({
        idLead: idLead,
        idMotivoPerda: LOSS_REASON_ID_DEFAULT,
      });

      setIsLeadLoss(false);
      feedback('Atendimento alterado com sucesso', 'success');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLossReasonLead({id: '', descricao: ''});
      setLoadPerda(false);
      handleCloseModal();
      setTimeout(() => {
        setRefresh(!refresh);
      }, 100);
    }
  }, [refresh, idLead]);

  const handleLossReasonDescription = () => {
    if (lossReasonLead?.descricao && lossReasonLead.descricao.length > 18) {
      return lossReasonLead.descricao.substring(0, 18) + '...';
    }
    return lossReasonLead?.descricao;
  };

  const origemLead = useMemo(() => {
    switch (lead?.origemLead.idLeadOrigem) {
      case 1:
        return ' - Interno';
      case 2:
        return ' - Externo';
      default:
        return '';
    }
  }, [lead?.origemLead.idLeadOrigem]);

  useEffect(() => {
    const fetchData = async () => {
      const formData = await EditLeadService.getInternalEditData(
        idCampanha,
        idLead,
      );
      setInternalEditForm(formData);
    };
    fetchData();
  }, [idCampanha, idLead]);

  return (
    <>
      <Grid container spacing={4} style={{display: 'flex'}}>
        <Grid item xs={12} sm={9} style={{display: 'flex'}}>
          {isLoading || isLoadingLead ? (
            <Skeleton variant="rect" height={150} width="100%" />
          ) : (
            <Card className={classes.customCard} style={{display: 'flex'}}>
              <CardContent>
                <Grid direction="row">
                  <Box display="flex" marginTop={1}>
                    <Grid container item xs={3}>
                      <Grid item xs={1}>
                        <Box marginTop={1}>
                          <Avatar style={{backgroundColor: '#d6d6d6'}}>
                            {makeAvatar}
                          </Avatar>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container item direction="column">
                      <Grid item>
                        <Typography variant="h5">
                          <b>{lead?.nomeLead}</b>
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="subtitle1">
                          {lead?.nomeCampanha}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container item direction="column">
                      <Grid item>
                        <Box display={'flex'} alignItems={'center'}>
                          <AccountBalanceIcon
                            style={{marginRight: 4, color: '#d6d6d6'}}
                          />
                          <Typography variant="subtitle1">
                            {lead?.estabelecimento}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          marginTop={1}
                          marginBottom={1}>
                          <SchoolIcon
                            style={{marginRight: 4, color: '#d6d6d6'}}
                          />
                          <Typography variant="subtitle1">
                            {lead?.oferta}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container item direction="column">
                      <Grid item>
                        <Box display={'flex'} alignItems={'center'}>
                          <MailIcon
                            style={{marginRight: 4, color: '#d6d6d6'}}
                          />
                          <Typography variant="subtitle1">
                            {lead?.email}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item>
                        <Box
                          display={'flex'}
                          marginTop={1.3}
                          alignItems={'center'}>
                          <PhoneIcon
                            style={{marginRight: 4, color: '#d6d6d6'}}
                          />
                          <Typography variant="subtitle1">
                            {lead?.telefone}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>

        <Grid item xs={12} sm={3} style={{display: 'flex'}}>
          {isLoading || isLoadingLead ? (
            <Skeleton variant="rect" height={150} width="100%" />
          ) : (
            <Card
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
              className={
                lead?.idMotivoPerda === LOSS_REASON_ID_DEFAULT
                  ? classes.card
                  : classes.cardPerda
              }>
              {loadPerda ? <LinearProgress /> : <></>}
              <Box p={3}>
                <Grid item container direction="column">
                  <Typography variant="subtitle2">
                    {lead?.idMotivoPerda !== LOSS_REASON_ID_DEFAULT
                      ? 'Venda Perdida'
                      : 'Situação'}
                  </Typography>

                  {lead?.idMotivoPerda === LOSS_REASON_ID_DEFAULT ? (
                    <Typography variant="h5">
                      <b>{lead?.situacaoAtualNoFunil}</b>
                    </Typography>
                  ) : (
                    <Typography variant="h5">
                      <b>{handleLossReasonDescription()}</b>
                    </Typography>
                  )}

                  <Typography variant="subtitle2">
                    <Box display={'flex'}>
                      Última iteração: {''}
                      {lead?.ultimaIteracao
                        .substring(0, 10)
                        .split('-')
                        .reverse()
                        .join('/') || <i>Não informado</i>}
                    </Box>
                  </Typography>

                  {lead?.idMotivoPerda !== LOSS_REASON_ID_DEFAULT ? (
                    <Grid xs={12}>
                      <Box
                        display={'flex'}
                        justifyContent={'end'}
                        marginTop={2}>
                        <Button
                          color="inherit"
                          variant="outlined"
                          size="medium"
                          onClick={handleDesfazerPerda}>
                          Desfazer Perda
                        </Button>
                      </Box>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Box>
            </Card>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={4} style={{display: 'flex'}}>
        <Grid item xs={12} sm={9} style={{display: 'flex'}}>
          <Box className={classes.customCard}>
            {isLoading || isLoadingLead ? (
              <Skeleton variant="rect" height={80} width="100%" />
            ) : (
              <Stepper activeStep={0}>
                {lead?.funil.map((item, index) => (
                  <Step
                    key={index}
                    completed={item.finalizado}
                    active={item.atual}>
                    <StepLabel>{item.situacao}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            )}
            <Box
              marginTop={1}
              marginLeft={3}
              sx={{borderBottom: 1, borderColor: 'divider'}}>
              <Tabs
                onChange={handleChange}
                value={value}
                disabled={isLoading || isLoadingLead}>
                <Tab label="Formulário" {...TabProps(0)} />

                <Tab label="Atendimentos" {...TabProps(1)} />

                <Tab label="Tarefas" {...TabProps(2)} />

                {lead?.possuiTaxa && <Tab label="Pagamento" {...TabProps(3)} />}
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {internalEditForm && internalEditForm.linkFormulario && (
                <Box marginBottom={3}>
                  <TableContainer
                    component={Paper}
                    style={{
                      marginTop: 10,
                    }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell width="80px">Ações</TableCell>
                          <TableCell>URL</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Tooltip title={`Copiar URL`}>
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  copyToClipboard(
                                    internalEditForm?.linkFormulario ?? '',
                                  )
                                }>
                                <FileCopy />
                              </IconButton>
                            </Tooltip>
                          </TableCell>

                          <Tooltip title={`Copiar URL`}>
                            <TableCell
                              style={{
                                maxWidth: '200px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              onClick={() =>
                                copyToClipboard(
                                  internalEditForm?.linkFormulario ?? '',
                                )
                              }>
                              <Link
                                color="primary"
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  copyToClipboard(
                                    internalEditForm?.linkFormulario ?? '',
                                  );
                                }}>
                                {internalEditForm?.linkFormulario ?? ''}
                              </Link>
                            </TableCell>
                          </Tooltip>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}

              <Paper elevation={3}>
                <Box height="100vh">
                  <iframe
                    src={`${Environment.URL_FORM}/formulario-interno-com-respostas/${idCampanha}/${idLead}`}
                    frameBorder="0"
                    height="100%"
                    width="100%"
                  />
                </Box>
              </Paper>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box marginBottom={2}>
                <ModalAgendamento
                  isPendete={
                    String(usaAtendimento?.nomeSituacaoDeAgenda) === 'Pendente'
                  }
                  idLead={idLead}
                  idSituacaoLeadFunil={lead?.idSituacaoAtualNoFunil}
                  idOferta={idOferta}
                  trigger={refresh}
                  setTrigger={setRefresh}
                />
              </Box>
              <Atendimentos idLead={idLead} trigger={refresh} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ModalNovaTarefa
                trigger={refresh}
                setTrigger={setRefresh}
                idLead={idLead}
              />
              <Tarefas trigger={refresh} idLead={idLead} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              {lead && (
                <Payment
                  idLead={idLead}
                  taxaComErro={lead.taxaComErro}
                  onGeneratedLink={() => setRefresh((state) => !state)}
                />
              )}
            </TabPanel>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3} style={{display: 'flex'}}>
          <Box width="100%" display="flex" pt={3}>
            <Box mr={2}>
              <Divider orientation="vertical" />
            </Box>
            <Box width="100%">
              <Typography variant="body1">
                <b>Agendamentos</b>
              </Typography>
              {isLoading || isLoadingLead ? (
                <Box mt={2}>
                  <Skeleton variant="rect" width="100%" height={50} />
                </Box>
              ) : (
                <Box
                  mt={2}
                  width="100%"
                  component={Paper}
                  className={classes.cardBorder}>
                  {usaAtendimento ? (
                    <div>
                      <Box
                        width={'100%'}
                        display={'flex'}
                        alignItems={'center'}
                        padding={1}
                        paddingBottom={0}
                        marginBottom={2}>
                        <CalendarTodayIcon
                          fontSize="small"
                          style={{marginRight: 6, color: '#6e6e6e'}}
                        />
                        <Typography variant="h6" style={{color: '#6e6e6e'}}>
                          {(usaAtendimento.dataAgenda &&
                            usaAtendimento?.dataAgenda
                              .substring(0, 10)
                              .split('-')
                              .reverse()
                              .join('/')
                              .substring(0, 10)
                              .split('-')
                              .reverse()
                              .join('/')) || <i>Não informado</i>}
                        </Typography>
                      </Box>
                      <Box marginLeft={2}>
                        <Typography
                          style={{color: '#6e6e6e'}}
                          variant="caption">
                          Situação do agendamento:
                        </Typography>
                      </Box>
                      <Box marginLeft={2}>
                        <Typography variant="h6" style={{color: '#6e6e6e'}}>
                          {usaAtendimento?.nomeSituacaoDeAgenda || (
                            <i>Não informado</i>
                          )}
                        </Typography>
                      </Box>
                      <Box
                        padding={1}
                        paddingBottom={2}
                        marginTop={-1}
                        marginRight={1}
                        display={'flex'}
                        justifyContent={'end'}>
                        <ModalEditAgendamento
                          setTrigger={setRefresh}
                          trigger={refresh}
                          idLead={idLead}
                          idOferta={idOferta}
                          idDisponibilidade={
                            usaAtendimento.idDisponibilidadeDeAgenda
                          }
                        />
                      </Box>
                    </div>
                  ) : (
                    <Box p={2} display="flex">
                      <EventBusyIcon />
                      <Box ml={1}>
                        <Typography>Não possui agendamento</Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}

              <Box mt={3}>
                <Typography variant="body1">
                  <b>Origem do lead {origemLead}</b>
                </Typography>
              </Box>

              {isLoading || isLoadingLead ? (
                <Box mt={2}>
                  <Skeleton variant="rect" width="100%" height={150} />
                </Box>
              ) : (
                <OrigemLead origemLead={lead?.origemLead} />
              )}

              <Box mt={3}>
                <Typography variant="body1">
                  <b>Linha do tempo</b>
                </Typography>
              </Box>
              {isLoading || isLoadingLead ? (
                <Box mt={2}>
                  <Skeleton variant="rect" width="100%" height={150} />
                </Box>
              ) : (
                <>
                  {lead?.linhaDoTempo
                    .slice(0, cardsToShow)
                    .map((lead, index) => (
                      <Box
                        key={index}
                        p={2}
                        mt={2}
                        width="100%"
                        component={Paper}
                        className={classes.paper}>
                        <Box display="flex" alignItems="center">
                          <EventIcon
                            fontSize="small"
                            style={{marginRight: 4}}
                          />
                          <Typography>
                            {getCardsDateTime(lead.dataHora)}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={1}>
                          <TimelineDescriptionIcon type={lead.tipo} />
                          <Typography>{lead.descricao}</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={1}>
                          <PersonIcon
                            fontSize="small"
                            style={{marginRight: 4}}
                          />
                          <Typography>{lead.atendente}</Typography>
                        </Box>
                      </Box>
                    ))}
                  {cardsToShow >= 5 && (
                    <Box
                      width={'100%'}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onClick={() => setOpenModalCards(true)}>
                      <Typography
                        variant="button"
                        color="initial"
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}>
                        Ver mais
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Footer
        loading={isLoadingEtapa}
        onSave={nextStep}
        disabled={isLoadingEtapa}
        onGoBack={() => {
          navigate('/manutencao-lead');
        }}
        goBackStartIcone={<KeyboardBackspaceIcon />}
        goBackLabel="Voltar para a lista"
        onSaveLabel="Avançar Etapa"
        onLoseMark={() => {
          handleOpenModal();
        }}
        hideSaveButton={hideSaveButton}
        loadingStepper={isLoading}
        isLeadLoss={isLeadLoss}
      />

      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="xl">
        <DialogTitle>
          <Typography variant="h6">Informe o motivo da perda</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container style={{width: 400}}>
            <FormControl fullWidth variant="outlined">
              <Select>
                {lossReasons?.map((item) => (
                  <MenuItem
                    key={item.id && item.id}
                    value={item.id && item.id}
                    onClick={() =>
                      setLossReasonSelected({
                        ...lossReasonSelected,
                        idMotivoPerda: item.id,
                      })
                    }>
                    {item.descricao}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            style={{marginRight: 18}}
            onClick={handleCloseModal}>
            cancelar
          </Button>
          <Button color="primary" variant="contained" onClick={handlePostPerda}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <ModalSeeMoreCards
        openModal={openModalCards}
        onClose={handleCloseModalCards}
        timeline={lead?.linhaDoTempo}
      />
    </>
  );
};
