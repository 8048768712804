import {Box, Paper, Tooltip, Typography, useTheme} from '@material-ui/core';

import LinkIcon from '@material-ui/icons/Link';
import PeopleIcon from '@material-ui/icons/People';
import CampaignIcon from '@mui/icons-material/Campaign';
import DescriptionIcon from '@material-ui/icons/Description';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import {TParametro} from 'shared/services/api/ExternalUrlService';
import {IOrigemLead} from 'shared/services/api/EditLeadService';
import {useCallback} from 'react';

interface IProps {
  origemLead?: IOrigemLead;
}

export const OrigemLead: React.FC<IProps> = ({origemLead}) => {
  const theme = useTheme();

  const getValue = useCallback(
    (parametro: TParametro) =>
      origemLead?.parametrosLinkCustomizado?.find(
        (param) => param.parametro === parametro,
      ),
    [origemLead?.parametrosLinkCustomizado],
  );

  return (
    <Box
      p={2}
      mt={2}
      width="100%"
      component={Paper}
      border="1px solid"
      borderColor={theme.palette.grey[300]}>
      {origemLead &&
      origemLead.idTipoServicoPublicidade === 1 &&
      origemLead.parametrosLinkCustomizado &&
      origemLead.parametrosLinkCustomizado.length > 0 ? (
        <>
          {getValue('utm_source') && (
            <Box display="flex" alignItems="center" gridGap={8}>
              <Tooltip title="Fonte da campanha" arrow>
                <PeopleIcon />
              </Tooltip>
              <Typography>{getValue('utm_source')?.valor}</Typography>
            </Box>
          )}
          {getValue('utm_medium') && (
            <Box display="flex" alignItems="center" gridGap={8} mt={1}>
              <Tooltip title="Meio de marketing" arrow>
                <CampaignIcon />
              </Tooltip>
              <Typography>{getValue('utm_medium')?.valor}</Typography>
            </Box>
          )}
          {getValue('utm_campaign') && (
            <Box display="flex" alignItems="center" gridGap={8} mt={1}>
              <Tooltip title="Nome da campanha" arrow>
                <LinkIcon />
              </Tooltip>
              <Typography>{getValue('utm_campaign')?.valor}</Typography>
            </Box>
          )}
          {getValue('utm_term') && (
            <Box display="flex" alignItems="center" gridGap={8} mt={1}>
              <Tooltip title="Termo da campanha" arrow>
                <DescriptionIcon />
              </Tooltip>
              <Typography>{getValue('utm_term')?.valor}</Typography>
            </Box>
          )}
          {getValue('utm_content') && (
            <Box display="flex" alignItems="center" gridGap={8} mt={1}>
              <Tooltip title="Conteúdo da campanha" arrow>
                <InsertDriveFileIcon />
              </Tooltip>
              <Typography>{getValue('utm_content')?.valor}</Typography>
            </Box>
          )}
        </>
      ) : (
        <Typography>Não possui parâmetros</Typography>
      )}
    </Box>
  );
};
